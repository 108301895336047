import {
  SettingOutlined,
  TeamOutlined,
  BankOutlined,
  AliwangwangOutlined,
  AndroidOutlined,
  TagsOutlined,
  CommentOutlined,
  UserOutlined,
  LayoutOutlined,
  GroupOutlined,
  AreaChartOutlined,
  HighlightOutlined,
  EditOutlined,
  MessageOutlined,
  PieChartOutlined,
  OrderedListOutlined
} from '@ant-design/icons';
import { Menu } from 'antd';
import { selectTotalUnread } from 'app/pages/ChatPage/slice/selectors';
import queryString from 'querystring';
import React, { useEffect, useMemo } from 'react';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { selectGlobalSlice } from 'app/slice/selectors';

interface SubMenuProps {
  link: string;
  title: string;
  key?: string;
}

interface MenuDataProps {
  key: string;
  label: string;
  icon?: any;
  link: string;
  children?: Array<SubMenuProps>;
  permission?: string;
}

const { SubMenu, Item } = Menu;

const items: Array<MenuDataProps> = [
  {
    key: 'chats',
    label: 'Hội thoại',
    link: '/chats',
    permission: 'customer_service_chat'
  },
  {
    key: 'customers',
    label: 'Quản lý khách hàng',
    link: '/customers'
  },
  {
    key: 'settings',
    label: 'Cài đặt',
    link: '/settings',
    permission: 'customer_service_chat_setting'
  },
  {
    key: 'report',
    label: 'Báo cáo',
    link: '/report',
    permission: 'customer_service_chat_report'
  },
];

const MenuLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector(selectGlobalSlice);
  return (
    <Menu
      theme="light"
      mode="horizontal"
      items={items?.filter(item => !user?.is_subuser || [item?.permission].some((key: any) => user?.permissions?.includes(key)))}
      inlineCollapsed={false}
      className='base-antd-menu'
      selectedKeys={[location.pathname.split('/')[1]]}
      onClick={(e) => {
        if (e.key == 'customers') {
          window.open(`${process.env.REACT_APP_SME_ENDPOINT}/customer-service/customer-info`, '_blank');
        } else {
          navigate(`/${e.key}`)
        }
      }}
    />
  );
};

export default memo(MenuLayout);
