import { useQuery } from '@apollo/client';
import query_stores from 'graphql/queries/query_stores';
import React, { createContext, ReactNode, useContext, useMemo } from 'react';
import queryString from 'querystring';
import { pickBy } from 'lodash';
import { generateTypeDate } from 'app/pages/Report/ReportHelper';
interface ReportProviderProps {
    children: ReactNode
}

interface ReportContextProps {
    optionsStore?: any[],
    optionsChannel?: any[],
    variablesQuery?: {
        channel_codes?: string,
        store_ids?: string,
        from?: number,
        to?: number,
        type?: string
    }
}

const ReportContext = createContext<ReportContextProps>({});

export function useReportContext() {
    return useContext(ReportContext);
};

export function ReportProvider({ children }: ReportProviderProps) {
    const params = queryString.parse(location.search.slice(1, 100000)) as any;

    const { data: dataStores } = useQuery(query_stores, {
        fetchPolicy: 'cache-and-network'
    });

    const optionsChannel = useMemo(() => {
        return dataStores?.op_connector_channels?.map(channel => ({
            ...channel,
            value: channel?.code,
            label: channel?.name
        }))
    }, [dataStores]);

    const optionsStore = useMemo(() => {
        const stores = dataStores?.sc_conversation_stores?.map(store => {
            const channel = dataStores?.op_connector_channels?.find(cn => cn?.code == store?.connector_channel_code);
            return {
                ...store,
                channel,
                value: store?.id,
                label: store?.name
            }
        })
        return stores || []
    }, [dataStores]);

    const variablesQuery = useMemo(() => {
        const [from, to] = [
            Number(params?.from) || null,
            Number(params?.to) || null,
        ];

        const variables = {
            channel_codes: params?.channel_codes || null,
            store_ids: params?.store_ids || null,
            type: generateTypeDate(from, to),
            from,
            to,
        }

        return pickBy(variables, value => !!value)
    }, [params]);

    const value = useMemo(() => {
        return {
            optionsStore, optionsChannel, variablesQuery
        }
    }, [optionsStore, optionsChannel, variablesQuery]);

    return (
        <ReportContext.Provider value={value}>
            {children}
        </ReportContext.Provider>
    )
}