import React, { Fragment, memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectGlobalSlice } from "app/slice/selectors";

const AuthorizationWrapper = ({ keys, children }) => {
    const { user } = useSelector(selectGlobalSlice);
    const isHasPermission = useMemo(() => {
        if (!!user?.is_subuser && !keys.some((key: any) => user?.permissions?.includes(key))) {
            return false
        }
        return true
    }, [keys]);
    if (!isHasPermission) {
        return null;
    }

    return (
        <Fragment>
            {children}
        </Fragment>
    )
}

export default memo(AuthorizationWrapper);