import gql from 'graphql-tag';

export default gql`
    query conversationLabelList($page: Int, $pageSize: Int) {
        conversationLabelList(page: $page, pageSize: $pageSize) {
            items {
                color
                id
                title
            }
            message
            page
            pageSize
            success
            totalPages        
        } 
    }
`;
