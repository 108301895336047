import client from "apollo";
import { auth } from "../firebase";
import mutate_subUserRefreshToken from "graphql/mutations/mutate_subUserRefreshToken";

const refreshToken = async () => {
  try {
    const refreshToken = localStorage.getItem('refresh_token');

    if (!!refreshToken) {
      window.localStorage.removeItem('accessToken');
      const { data } = await client.mutate({
        mutation: mutate_subUserRefreshToken,
        variables: {
          token: refreshToken
        }
      });

      if (!!data?.subUserRefreshToken?.success) {
        localStorage.setItem('refresh_token', data?.subUserRefreshToken?.refreshToken);
        localStorage.setItem('accessToken', data?.subUserRefreshToken?.accessToken)
        return;
      }
    }


    if (!refreshToken) {
      auth.onAuthStateChanged(async user => {
        if (user) {
          let token = await user.getIdToken(true)
          localStorage.setItem('accessToken', token);
          return;
        }

        return;
      });
    }
  } catch (error) {
    return;
  }
}

export default refreshToken;