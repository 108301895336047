import AuthorizeRoute from 'app/components/AuthorizeRoute';
import { NotFoundPage } from 'app/components/NotFoundPage/Loadable';
import { SocketProvider } from 'app/contexts/SocketContext';
import { useGlobalSliceSlice } from 'app/slice';
import React, { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import AuthorizationPage from '../Authentication/AuthorizationPage';
import { ChatPage } from '../ChatPage/Loadable';
import ChannelsPage from '../ChannelPage/Channels';
import ChannelsAddRedirectPage from '../ChannelPage/ChannelsAddRedirect';
import Dashboard from './Dasboard';
import SettingPage from '../Setting';
import QuickMessage from '../Setting/QuickMessage';
import LayoutSetting from 'app/pages/Setting/components/Layout';
import LayoutReport from './LayoutReport';
import { ReportConversation, ReportLabel, ReportOverview } from 'app/pages/Report/Loadable';
import { ReportProvider } from 'app/contexts/ReportContext';

const Layout = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const { actions } = useGlobalSliceSlice();


  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      window.location.replace(`${process.env.REACT_APP_SME_ENDPOINT}/auth/login?source=chat`);
    }
  }, []);

  return (
    <SocketProvider>
      <Dashboard>
        <Routes>
          <Route
            path={'/chats'}
            element={
              <AuthorizeRoute keys={['customer_service_chat']}>
                <ChatPage />
              </AuthorizeRoute>
            }
          />
          <Route
            path={'/setting/channels'}
            element={<AuthorizeRoute keys={['customer_service_chat_store_connect']}>
              <ChannelsPage />
            </AuthorizeRoute>}
          />
          <Route
            path={'/settings'}
            element={<AuthorizeRoute keys={['customer_service_chat_setting']}>
              <SettingPage />
            </AuthorizeRoute>}
          />
          <Route
            path={'/settings/quick-message'}
            element={<AuthorizeRoute keys={['customer_service_chat_setting']}>
              <LayoutSetting>
                <QuickMessage />
              </LayoutSetting>
            </AuthorizeRoute>
            }
          />
          <Route path={'/report'}>
            <Route
              path={''}
              element={<AuthorizeRoute keys={['customer_service_chat_report']}>
                <ReportProvider>
                  <LayoutReport>
                    <ReportOverview />
                  </LayoutReport>
                </ReportProvider>
              </AuthorizeRoute>}
            />
            <Route
              path={'conversation'}
              element={<AuthorizeRoute keys={['customer_service_chat_report']}>
                <ReportProvider>
                  <LayoutReport>
                    <ReportConversation />
                  </LayoutReport>
                </ReportProvider>
              </AuthorizeRoute>
              }
            />
            <Route
              path={'label'}
              element={
                <AuthorizeRoute keys={['customer_service_chat_report']}>
                  <ReportProvider>
                    <LayoutReport>
                      <ReportLabel />
                    </LayoutReport>
                  </ReportProvider>
                </AuthorizeRoute>
              }
            />
          </Route>
          <Route
            path={'/setting/channels/add/:channel/connect-redirect'}
            element={<AuthorizeRoute keys={['customer_service_chat_store_create']}>
              <ChannelsAddRedirectPage />
            </AuthorizeRoute>}
          />
          <Route path={'/'} element={<Navigate to="/chats" />} />
          <Route path={'/error/403'} element={<AuthorizationPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Dashboard>
    </SocketProvider>
  );
};

export default memo(Layout);
