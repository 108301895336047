import { useQuery } from '@apollo/client';
import query_conversationLabelList from 'graphql/queries/query_conversationLabelList';
import query_stores from 'graphql/queries/query_stores';
import React, { createContext, ReactNode, useCallback, useContext, useMemo, useRef, useState } from 'react';

interface LayoutProviderProps {
    children: ReactNode
}

export interface BreadcrumbProps {
    title: string,
    pathname: string
}

interface LayoutContextProps {
    breadcrumbs?: Array<BreadcrumbProps>,
    appendBreadcrumb?: any,
    optionsStore?: any,
    loadingConversationLabel?: boolean,
    dataConversationLabel?: any,
    messageListReferance?: any
}

const LayoutContext = createContext<LayoutContextProps>({});

export function useLayoutContext() {
    return useContext(LayoutContext);
};

export function LayoutProvider({ children }: LayoutProviderProps) {
    const messageListReferance = useRef<any>(null);
    const [breadcrumbs, setBreadcrumbs] = useState<Array<BreadcrumbProps>>([]);

    const { data: dataStores } = useQuery(query_stores, {
        fetchPolicy: 'cache-and-network'
    });

    const { loading: loadingConversationLabel, data: dataConversationLabel } = useQuery(query_conversationLabelList, {
        fetchPolicy: 'cache-and-network',
        variables: { page: 1, pageSize: 20 }
    });

    const optionsStore = useMemo(() => {
        const stores = dataStores?.sc_conversation_stores?.map(store => {
            const channel = dataStores?.op_connector_channels?.find(cn => cn?.code == store?.connector_channel_code);
            return {
                ...store,
                channel
            }
        })
        return stores || []
    }, [dataStores]);

    const appendBreadcrumb = useCallback(
        (item: Array<BreadcrumbProps>) => {
            setBreadcrumbs(item)
        }, []
    );

    const value = useMemo(
        () => {
            return {
                breadcrumbs, appendBreadcrumb, optionsStore, loadingConversationLabel, dataConversationLabel, messageListReferance
            }
        }, [breadcrumbs, optionsStore, loadingConversationLabel, dataConversationLabel, messageListReferance]
    );

    return (
        <LayoutContext.Provider value={value}>
            {children}
        </LayoutContext.Provider>
    );
};