import { CaretRightOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import { Button, Card, Collapse, Empty, Flex, List, Spin, Switch, Tooltip, Typography, theme } from "antd";
import ModalConfirm from "app/components/ModalConfirm";
import { useLayoutContext } from "app/contexts/LayoutContext";
import mutate_chatTplMessageEnableSuggest from "graphql/mutations/mutate_chatTplMessageEnableSuggest";
import mutate_chatTplMessageGroupDelete from "graphql/mutations/mutate_chatTplMessageGroupDelete";
import query_chatTplMessageEnableSuggest from "graphql/queries/query_chatTplMessageEnableSuggest";
import query_chatTplMessageGroups from "graphql/queries/query_chatTplMessageGroups";
import React, { useCallback, useLayoutEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { showAlert } from "utils/helper";
import { QuickMessageWrapper } from '../Setting.styles';
import ModalGroupMessage from "../components/ModalGroupMessage";

const MAX_GROUP_MESSAGES = 25;
const MAX_MESSAGE_IN_GROUP = 10;
const { Text } = Typography;

const QuickMessage = () => {
    const { token } = theme.useToken();
    const { optionsStore } = useLayoutContext();
    const { appendBreadcrumb } = useLayoutContext();

    const [openModalGroupMess, setOpenModalGroupMess] = useState<boolean>(false);
    const [currentData, setCurrentData] = useState<any>(null);
    const [currentIdDelete, setCurrentIdDelete] = useState<string | null>(null);
    const [showConfirmEnable, setShowConfirmEnable] = useState<boolean>(false);

    const panelStyle: React.CSSProperties = {
        marginBottom: 12,
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        border: 'none',
    };

    useLayoutEffect(() => {
        appendBreadcrumb([
            {
                title: 'Cài đặt',
                pathname: '/settings',
            },
            {
                title: 'Tin nhắn nhanh',
                pathname: '/settings/quick-message',
            },
        ]);
    }, []);

    const { loading: loadingGetEnable, data: dataGetEnable } = useQuery(query_chatTplMessageEnableSuggest, {
        fetchPolicy: 'cache-and-network'
    });

    const { data: dataChatTplMessage, loading: loadingChatTplMessage } = useQuery(query_chatTplMessageGroups, {
        variables: {
            page: 1,
            pageSize: MAX_GROUP_MESSAGES
        }
    });

    const [enableSuggest, { loading: loadingEnableSuggest }] = useMutation(mutate_chatTplMessageEnableSuggest, {
        awaitRefetchQueries: true,
        refetchQueries: ['chatTplMessageEnableSuggest']
    });

    const [deleteGroupMessage, { loading: loadingDeleteGroupMessage }] = useMutation(mutate_chatTplMessageGroupDelete, {
        awaitRefetchQueries: true,
        refetchQueries: ['chatTplMessageGroups']
    });

    const onEnableSuggest = useCallback(async (value) => {
        try {
            const { data } = await enableSuggest({
                variables: {
                    enable: value ? 1 : 0
                }
            });

            setShowConfirmEnable(false);
            if (data?.chatTplMessageEnableSuggest?.success) {
                showAlert.success(!value ? 'Tắt gợi ý tin nhắn nhanh thành công' : 'Bật gợi ý tin nhắn nhanh thành công')
            } else {
                showAlert.error(!value ? 'Tắt gợi ý tin nhắn nhanh thất bại' : 'Bật gợi ý tin nhắn nhanh thất bại')
            }
        } catch (error) {
            showAlert.error('Có lỗi xảy ra, xin vui lòng thử lại')
        }
    }, []);

    const onDeleteGroupMessage = useCallback(async () => {
        try {
            const { data } = await deleteGroupMessage({
                variables: {
                    id: currentIdDelete
                }
            });

            setCurrentIdDelete(null);
            if (!!data?.chatTplMessageGroupDelete?.success) {
                showAlert.success("Xóa nhóm tin nhắn thành công");
            } else {
                showAlert.error(data?.chatTplMessageGroupDelete?.message || "Xóa nhóm tin nhắn thất bại");
            }
        } catch (error) {
            showAlert.error('Có lỗi xảy ra, xin vui lòng thử lại')
        }
    }, [currentIdDelete]);

    return <QuickMessageWrapper>
        <Helmet
            titleTemplate="Tin nhắn nhanh - UpBase"
            defaultTitle="Tin nhắn nhanh - UpBase"
        >
            <meta name="description" content="Tin nhắn nhanh - UpBase" />
        </Helmet>
        {openModalGroupMess && <ModalGroupMessage
            show={openModalGroupMess}
            currentData={currentData}
            optionsStore={optionsStore}
            onHide={() => {
                setCurrentData(null);
                setOpenModalGroupMess(false);
            }}
        />}
        <ModalConfirm
            open={!!currentIdDelete}
            onConfirm={onDeleteGroupMessage}
            onHide={() => setCurrentIdDelete(null)}
            title="Hệ thống sẽ xoá nhóm tin nhắn này, bạn có đồng ý tiếp tục ?"
            loading={loadingDeleteGroupMessage}
        />
        <ModalConfirm
            open={showConfirmEnable}
            onConfirm={() => onEnableSuggest(false)}
            onHide={() => setShowConfirmEnable(false)}
            title="Nếu bạn tắt, hệ thống sẽ không gợi ý tin nhắn nhanh trong khi soạn thảo tin nhắn phản hồi. Bạn có muốn tiếp tục ?"
            loading={loadingEnableSuggest}
        />
        <Spin spinning={loadingGetEnable}>
            <Card className="card-switch" title={false}>
                <Flex align="center" gap={12}>
                    <Text strong>Hệ thống tự động hiển thị gợi ý tin nhắn nhanh</Text>
                    <Spin spinning={loadingEnableSuggest}>
                        <Switch
                            checked={!!dataGetEnable?.chatTplMessageEnableSuggest}
                            onChange={value => {
                                if (!!value) {
                                    onEnableSuggest(value)
                                } else {
                                    setShowConfirmEnable(true);
                                }
                            }}
                        />
                    </Spin>
                </Flex>
            </Card>
        </Spin>
        <Spin spinning={loadingChatTplMessage}>
            <Card className="card-message" title={false}>
                <Flex align="center" justify="space-between">
                    <Text strong>Nhóm tin nhắn nhanh ({dataChatTplMessage?.chatTplMessageGroups?.items?.length}/{MAX_GROUP_MESSAGES})</Text>
                    <Button
                        type="primary"
                        className="btn-base"
                        disabled={dataChatTplMessage?.chatTplMessageGroups?.items?.length > MAX_GROUP_MESSAGES - 1}
                        onClick={() => setOpenModalGroupMess(true)}
                    >
                        Thêm nhóm tin nhắn
                    </Button>
                </Flex>
                {(!dataChatTplMessage || dataChatTplMessage?.chatTplMessageGroups?.items?.length == 0) && <Flex style={{ marginTop: 20 }} justify="center">
                    <Empty description="Chưa có nhóm tin nhắn nhanh" />
                </Flex>}
                {dataChatTplMessage?.chatTplMessageGroups?.items?.length > 0 && (
                    <Collapse
                        className="collapse-message"
                        bordered={false}
                        key="id"
                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                        style={{ background: token.colorBgContainer }}
                        items={dataChatTplMessage?.chatTplMessageGroups?.items?.map(item => {
                            return {
                                key: item?.id,
                                label: <Flex justify="space-between" align="center">
                                    <Flex align="center" gap={8}>
                                        <Text>{item?.name} ({item?.messages?.length}/{MAX_MESSAGE_IN_GROUP})</Text>
                                        <Tooltip
                                            placement="bottom"
                                            title={<Flex vertical gap={10}>
                                                {optionsStore?.filter(store => item?.stores?.includes(store?.id))?.map(store => (
                                                    <Flex align="center" gap={4}>
                                                        <img src={store?.channel?.logo_asset_url} style={{ width: 16, height: 16, borderRadius: 4 }} />
                                                        <Text style={{ color: '#fff' }}>{store?.name}</Text>
                                                    </Flex>
                                                ))}
                                            </Flex>}
                                        >
                                            <Text type="danger">{`<${item?.stores?.length} gian hàng áp dụng>`}</Text>
                                        </Tooltip>
                                    </Flex>
                                    <Flex align="center" gap={20}>
                                        <Tooltip title="Chỉnh sửa nhóm tin nhắn" placement="bottom">
                                            <EditOutlined
                                                className="color-success icon-btn"
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    setOpenModalGroupMess(true);
                                                    setCurrentData(item);
                                                }}
                                            />
                                        </Tooltip>
                                        <Tooltip title="Xóa nhóm tin nhắn" placement="bottom">
                                            <DeleteOutlined
                                                className="color-danger icon-btn"
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    setCurrentIdDelete(item?.id);
                                                }}
                                            />
                                        </Tooltip>
                                    </Flex>
                                </Flex>,
                                children: <List
                                    className="list-message"
                                    size="small"
                                    bordered={false}
                                    dataSource={item?.messages || []}
                                    renderItem={(item: any) => <List.Item>
                                        <Tooltip title={item} placement="bottom">
                                            <Typography.Paragraph ellipsis={{ rows: 2 }}>
                                                {item}
                                            </Typography.Paragraph>
                                        </Tooltip>
                                    </List.Item>}
                                />,
                                style: panelStyle,
                            }
                        })}
                    />
                )}
            </Card>
        </Spin>
    </QuickMessageWrapper >
};

export default QuickMessage;