import { EyeInvisibleOutlined, EyeTwoTone, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Form, Input, Row, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import LoginWrapper from './Login.style';
import { showAlert } from 'utils/helper';
import { useGlobalSliceSlice } from 'app/slice';
import { selectGlobalSlice } from 'app/slice/selectors';
import logo from '../../../assets/logo512.png'


const { Text } = Typography;
const key_login = 'login';

const Login = () => {
    const { actions } = useGlobalSliceSlice()
    const { accessToken } = useSelector(selectGlobalSlice)

    const navigate = useNavigate();
    const location: any = useLocation();
    const [form] = Form.useForm();
    const dispatch = useDispatch()
    const [isChecked, onChangeRemember] = useState(false)

    const { mutate, loading } = { loading: false, mutate: null }//useMutation(login)

    const [openModal, setOpenModal] = useState<boolean>(false);

    useEffect(() => {
        if (!!accessToken) {
            const { from } = location?.state || {}
            navigate(from?.pathname || '/')
        }
    }, [accessToken, location?.state])

    const onFinish = useCallback(
        async (value: any) => {
            let { username, password } = value;
            console.log({ username, password, isChecked })
            localStorage.setItem('accessToken', "payload.data.token")
            dispatch(actions.saveToken({ token: "payload.data.token", email: username }))
            // let { payload } = await mutate({
            //     email: username, password,
            // })

            // console.log('payload', payload)
            // if (payload?.code == 200) {
            //     localStorage.setItem('accessToken', payload.data.token)
            //     dispatch(actions.saveToken({ token: payload.data.token, email: username }))
            //     showAlert.success('Đăng nhập thành công.');
            //     navigate('/tickets');

            //     if (isChecked) {
            //         localStorage.setItem('username', username)
            //         localStorage.setItem('password', password)
            //     } else {
            //         localStorage.removeItem('username')
            //         localStorage.removeItem('password')
            //     }

            //     localStorage.setItem('email', username)
            // } else {
            //     showAlert.error(payload?.message || "Đăng nhập không thành công.");
            // }

        }, [location?.state, isChecked]
    );

    const validatePassword = (rule, value, callback) => {
        try {
            callback();
        } catch (err) {
            callback(err)
        }
    };

    return (
        <LoginWrapper>
            <Helmet
                titleTemplate="Đăng nhập - UpBase"
                defaultTitle="Đăng nhập - UpBase"
            >
                <meta name="description" content="Đăng nhập - UpBase" />
            </Helmet>
            {/* {isSpinLogin && (
                <Spin size='large' className="spin-login" />
            )} */}

            <Card className="card">
                <Text className="txt-title-brand">LOGIN</Text>
                <Form
                    form={form}
                    onFinish={onFinish}
                    className="form-brand"
                    layout="vertical"
                    initialValues={{
                        username: localStorage.getItem('username') || '',
                        password: localStorage.getItem('password') || '',
                    }}
                    autoComplete='none'
                >
                    <Form.Item
                        name="username"
                        label="Email"
                        rules={[
                            { required: true, message: 'Required' }
                        ]}
                    >
                        <Input
                            className="input-item"
                            placeholder="Email *"
                            disabled={loading}
                            size="large"
                            prefix={<UserOutlined style={{ marginRight: 4 }} />}
                            allowClear
                            autoComplete="off"
                        />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[
                            { required: true, message: 'Required' },
                            { validator: validatePassword }
                        ]}
                    >
                        <Input.Password
                            className="input-item"
                            placeholder="Password *"
                            disabled={loading}
                            prefix={<SettingOutlined style={{ marginRight: 4 }} />}
                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            autoComplete="nope"
                        />
                    </Form.Item>
                    <div className="" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Checkbox
                            checked={isChecked}
                            onChange={e => onChangeRemember(e.target.checked)}
                            style={{ marginBottom: 20, }}
                        >
                            Nhớ mật khẩu
                        </Checkbox>
                        <Text
                            style={{ color: '#019ef7', cursor: 'pointer' }}
                            onClick={() => {
                                setOpenModal(true);
                            }}
                        >
                            Quên mật khẩu
                        </Text>
                    </div>
                    <Form.Item>
                        <Button
                            className="btn-brand btn-login"
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            Login
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </LoginWrapper >
    );
};

export default Login;