import { lazyLoad } from "utils/loadable";

export const ReportConversation = lazyLoad(
  () => import("./ReportConversation"),
  (module) => module.default
);

export const ReportOverview = lazyLoad(
  () => import("./ReportOverview"),
  (module) => module.default
);

export const ReportLabel = lazyLoad(
  () => import("./ReportConversationLabel"),
  (module) => module.default
);
