import React from "react";
import Layout from "./components/Layout";
import { Navigate, Route, Routes } from 'react-router-dom';
import QuickMessage from "./QuickMessage";

const SettingPage = () => {
    return (
        <Layout>
            <Routes>
                <Route path={'/'} element={<Navigate to="/settings/quick-message" />} />
            </Routes>
        </Layout>
    )
};

export default SettingPage;