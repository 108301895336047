import styled from "styled-components";

export default styled.div`
  .layout-sider {
    overflow: auto;
    min-height: calc(100vh - 64px);
    position: fixed;
    left: 0px;
    top: 64px;
    bottom: 0px;
    background: #fff;
    
    .ant-menu {
      margin-top: 4px;
    }

    .ant-menu-item {
      display: flex;
      align-items: center;
    }

    .base-antd-menu {
      min-width: 50vw;
      
      .ant-menu-title-content {
        padding-left: 4px !important;
      }
    }
  }

  .site-layout {
    background-color: #eff2f5 !important;
    min-height: calc(100vh - 64px);
    padding: 20px;
    margin-left: 240px;

    .site-content {
      margin-top: 20px;
    }
  }
`;

export const QuickMessageWrapper = styled.div`
  .card-switch {
    .ant-card-body {
      padding: 16px 24px;
    }
  }

  .card-message {
    margin-top: 20px;
  }

  .collapse-message {
    margin-top: 20px;
  }

  .icon-btn {
    font-size: 16px;
  }

  .list-message {
    background: #fff;
  }
`;